// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-contact-index-jsx": () => import("./../../../src/components/Contact/index.jsx" /* webpackChunkName: "component---src-components-contact-index-jsx" */),
  "component---src-components-figment-index-jsx": () => import("./../../../src/components/Figment/index.jsx" /* webpackChunkName: "component---src-components-figment-index-jsx" */),
  "component---src-components-games-game-category-page-index-jsx": () => import("./../../../src/components/Games/GameCategoryPage/index.jsx" /* webpackChunkName: "component---src-components-games-game-category-page-index-jsx" */),
  "component---src-components-games-game-index-page-index-jsx": () => import("./../../../src/components/Games/GameIndexPage/index.jsx" /* webpackChunkName: "component---src-components-games-game-index-page-index-jsx" */),
  "component---src-components-games-individual-game-page-index-jsx": () => import("./../../../src/components/Games/IndividualGamePage/index.jsx" /* webpackChunkName: "component---src-components-games-individual-game-page-index-jsx" */),
  "component---src-components-jobs-index-jsx": () => import("./../../../src/components/Jobs/index.jsx" /* webpackChunkName: "component---src-components-jobs-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-about-about-index-page-js": () => import("./../../../src/templates/About/AboutIndexPage.js" /* webpackChunkName: "component---src-templates-about-about-index-page-js" */),
  "component---src-templates-blog-blog-index-page-jsx": () => import("./../../../src/templates/Blog/BlogIndexPage.jsx" /* webpackChunkName: "component---src-templates-blog-blog-index-page-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-blog-posts-by-tag-jsx": () => import("./../../../src/templates/BlogPostsByTag.jsx" /* webpackChunkName: "component---src-templates-blog-posts-by-tag-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/HomePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-legal-legal-index-page-jsx": () => import("./../../../src/templates/Legal/LegalIndexPage.jsx" /* webpackChunkName: "component---src-templates-legal-legal-index-page-jsx" */),
  "component---src-templates-resources-resources-index-page-jsx": () => import("./../../../src/templates/Resources/ResourcesIndexPage.jsx" /* webpackChunkName: "component---src-templates-resources-resources-index-page-jsx" */),
  "component---src-templates-science-page-jsx": () => import("./../../../src/templates/SciencePage.jsx" /* webpackChunkName: "component---src-templates-science-page-jsx" */),
  "component---src-templates-stacked-landing-page-jsx": () => import("./../../../src/templates/stackedLandingPage.jsx" /* webpackChunkName: "component---src-templates-stacked-landing-page-jsx" */)
}

